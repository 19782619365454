import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  styled,
  Typography,
} from "@mui/material";
import logo from "../assets/logo4.png";
import { FaUser } from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutMember } from "../../../redux/slices/memberSlice";
import pages from "../../../constants/pages";

interface Props {
  window?: () => Window;
}

const drawerWidth = 260;
const navItems = [
  { text: "Home", link: "/" },
  { text: "Appointment", link: "/appointment/appointment-booking" },
  { text: "Log Out", link: "" },
];

const StyledAppBar = styled(AppBar)({
  background: "linear-gradient(90deg, #009963, #00b579)",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
});

const Logo = styled("img")({
  width: 120,
  marginLeft: "1rem",
  cursor: "pointer",
});

const MenuItems = styled("div")({
  display: "flex",
  alignItems: "center",
  "& a": {
    color: "#ffffff",
    fontWeight: 500,
    textDecoration: "none",
    fontSize: "16px",
    margin: "0 1rem",
    position: "relative",
    "&:hover": {
      color: "#ffde03",
      "&::before": {
        content: "''",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-2px",
        height: "3px",
        background: "#ffde03",
        transition: "width 0.3s ease",
        width: "100%",
      },
    },
  },
});

const DrawerLogoBox = styled(Box)({
  padding: "1rem",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#009963",
});

const StyledDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    backgroundColor: "#f5f5f5",
    color: "#333",
  },
});

export default function MenuNavbar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      sx={{ textAlign: "center", marginTop: "1rem", padding: "1rem" }}
      onClick={handleDrawerToggle}
    >
      <DrawerLogoBox>
        <Logo src={logo} alt="Logo" />
      </DrawerLogoBox>
      <List>
        {navItems.map((item) => (
          <Fragment key={item.text}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() =>
                  item.text === "Log Out" && dispatch(signOutMember())
                }
              >
                <ListItemText
                  primary={
                    <Typography
                      component={Link}
                      to={item.link}
                      sx={{
                        color: "#009963",
                        fontWeight: 600,
                        textDecoration: "none",
                        fontSize: "1rem",
                        "&:hover": {
                          color: "#00b579",
                        },
                      }}
                    >
                      {item.text}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleProfile = () => {
    if (!visitorData.ID) {
      navigate(pages.appointment.route + "login");
    } else {
      navigate(pages.Profile.route);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <StyledAppBar position="static">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ display: { sm: "none" } }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Logo src={logo} alt="Logo" onClick={() => navigate("/")} />
          </Box>
          <MenuItems sx={{ display: { xs: "none", sm: "flex" } }}>
            {navItems.map((item) => (
              <Link key={item.text} to={item.link}>
                {item.text}
              </Link>
            ))}
          </MenuItems>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton color="inherit">
              <IoIosNotifications />
            </IconButton>
            <IconButton color="inherit" onClick={() => handleProfile()}>
              <FaUser />
            </IconButton>
          </Box>
        </Toolbar>
      </StyledAppBar>
      <Box component="nav">
        <StyledDrawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          {drawer}
        </StyledDrawer>
      </Box>
    </Box>
  );
}
