import axios from "axios";

import BASE_URL from "../../../constants/baseURL";

export default async function signIn({
  authToken,
  agentName,
  eventID,
  visitorData,
  businessEntityId,
}) {
  console.log(visitorData, "visitorData");

  return {
    authToken: authToken,
    displayName: agentName || "",
    eventID: eventID,
    visitorData: {
      ID: visitorData?._id,
      lastName: visitorData?.last_name,
      gender: visitorData?.gender,
      emailID: visitorData?.email_id,
      faceData: visitorData?.face_data,
      firstName: visitorData?.first_name,
      onWhatsapp: visitorData?.on_whatsapp,
      phoneNumber: visitorData?.phone_number,
    },
  };
}
