import { Grid } from "@mui/material";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState, Fragment } from "react";
import FormControl from "@mui/material/FormControl";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { SelectField } from "../../../../components";
import { useAlert, useAxios } from "../../../../hooks";

const TextFieldWithCSS = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e8decf;

    border-radius: 12.5px;
  }

  .MuiOutlinedInput-root {
    font-size: 15px;
  }

  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgb(161, 130, 74);
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgb(161, 130, 74);
  }

  .MuiInputLabel-root {
    color: rgb(161, 130, 74);
  }

  .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
    background-color: #f5f5f5;
    font-size: 15px;
    border-radius: 12.5px;
  }

  .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #f5f5f5;
  }

  .MuiInputLabel-asterisk {
    color: ${(props) => (Boolean(props.required && !props.value) ? "red" : "")};
  }
`;

function Form({
  formik,
  premises,
  departments,
  subDepartments,
  setDepartments,
  setSubDepartments,
}) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  const [timeOptions, setTimeOptions] = useState([]);
  const [officerOptions, setOfficerOptions] = useState([]);
  const [disabledDate, setDisabledDate] = useState([]);

  const fetchDates = async (newValue) => {
    try {
      const response = await axios({
        url: `/appointment/appointment/get-time-slots?department_id=${formik.values.department}&date=${newValue}&officer_id=${formik.values.officer}`,
        method: "GET",
      });

      if (response.status && response.data.length > 0) {
        const dates = response.data;
        setTimeOptions(dates);
      } else {
        dispatch(
          alert({
            type: "error",
            message: "Booking dates not available",
          })
        );
      }
    } catch (error) {
      console.error("Error in fetching available dates", error);
    }
  };

  const handleOfficerChange = (event) => {
    const selectedOfficer = event.target.value;
    formik.setFieldValue("officer", selectedOfficer);

    axios({
      url: `/appointment/appointment/get-dates?department_id=${formik.values.department}&officer_id=${selectedOfficer}`,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setDisabledDate(response.data);
      }
    });
  };

  const handleDepartmentChange = async (event) => {
    const selectedDepartment = event.target.value;
    formik.setFieldValue("premises", selectedDepartment);

    try {
      const response = await axios({
        url: `/appointment/appointment/get-departments?premises=${selectedDepartment}`,
        method: "GET",
      });
      if (response.status) {
        setDepartments(response.data);
      }
    } catch (error) {
      console.error("Error in fetching office list", error);
    }
  };

  const handleOfficeChange = (event) => {
    const selectedSubDepartment = event.target.value;
    formik.setFieldValue("subDepartment", selectedSubDepartment);

    fetchOfficers(selectedSubDepartment);
  };

  const fetchOfficers = async (selectedSubDepartment) => {
    try {
      const response = await axios({
        url: `/appointment/appointment/get-officials?sub_department_id=${selectedSubDepartment}`,
        method: "GET",
      });
      if (response.status) {
        setOfficerOptions(response.data);
      }
    } catch (error) {
      console.error("Error in fetching officer list", error);
    }
  };

  const handleDateChange = (newValue) => {
    formik.setFieldValue("time", "");
    setTimeOptions([]);

    let time = new Date(newValue);

    fetchDates(time.getTime() / 1000);

    formik.setFieldValue("date", newValue);
  };

  const handleSubDepartmentChange = async (event) => {
    const selectedDepartment = event.target.value;
    formik.setFieldValue("department", selectedDepartment);

    try {
      const response = await axios({
        url: `/appointment/appointment/get-sub-departments?department_id=${selectedDepartment}`,
        method: "GET",
      });
      if (response.status) {
        setSubDepartments(response.data);
      }
    } catch (error) {
      console.error("Error in fetching office list", error);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        textTransform: "capitalize",
        boxShadow: "rgb(232, 196, 144) 3px 3px 5px -2px",
        padding: "15px 15px 20px 5px !important",
        borderRadius: "12.5px",
        border: "1px solid rgb(232, 222, 207)",
        marginTop: "5px",
      }}
    >
      <SelectField
        label="Select Premises"
        name="premises"
        value={formik.values.premises}
        options={premises.map((premise) => ({
          id: premise.premises_id,
          name: premise.name,
        }))}
        onChange={handleDepartmentChange}
        onBlur={formik.handleBlur}
        error={formik.touched.premises && Boolean(formik.errors.premises)}
      />

      <SelectField
        label="Select Department"
        name="department"
        value={formik.values.department}
        options={departments.map((item) => ({
          id: item._id,
          name: item.department_name,
        }))}
        onChange={handleSubDepartmentChange}
        onBlur={formik.handleBlur}
        error={formik.touched.department && Boolean(formik.errors.department)}
        disabled={departments.length === 0}
      />

      <SelectField
        label="Select Sub-Department"
        name="subDepartment"
        value={formik.values.subDepartment}
        options={subDepartments.map((office) => ({
          id: office.sub_department_id,
          name: office.sub_department_name,
        }))}
        onChange={handleOfficeChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.subDepartment && Boolean(formik.errors.subDepartment)
        }
        disabled={subDepartments.length === 0}
      />

      <SelectField
        label="Select Officer"
        name="officer"
        value={formik.values.officer}
        options={officerOptions.map((officer) => ({
          id: officer._id,
          name: officer.name,
        }))}
        onChange={handleOfficerChange}
        onBlur={formik.handleBlur}
        error={formik.touched.officer && Boolean(formik.errors.officer)}
        disabled={officerOptions.length === 0}
      />

      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl fullWidth variant="outlined">
            <DatePicker
              label={
                <>
                  Select Date
                  <span
                    style={{
                      color: "red",
                      float: "right",
                      paddingLeft: "5px",
                    }}
                  >
                    *
                  </span>
                </>
              }
              autoComplete="off"
              placeholder="Select Date"
              value={formik.values.date}
              onBlur={formik.handleBlur}
              error={formik.touched.date && Boolean(formik.errors.date)}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextFieldWithCSS
                  {...params}
                  name={"date"}
                  onBlur={formik.handleBlur}
                  error={formik.touched.date && Boolean(formik.errors.date)}
                  value={formik.values.date}
                />
              )}
              shouldDisableDate={(date) => {
                console.log(date, new Date());
                return (
                  disabledDate.filter((item, index) => {
                    return date == item;
                  }).length !== 0 || date < new Date()
                );
              }}
              inputFormat="dd/MM/yyyy"
            />
          </FormControl>
        </LocalizationProvider>
      </Grid>

      <SelectField
        label="Select Time Slot"
        name="time"
        value={formik.values.time || []}
        options={timeOptions.map((time) => ({
          id: time.slot_id,
          name: time.slot_time,
        }))}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.time && Boolean(formik.errors.time)}
        disabled={timeOptions.length === 0}
        multiple={true}
      />

      <SelectField
        label="Purpose of visit"
        name="PurposeVisit"
        value={formik.values.PurposeVisit}
        options={[
          { id: "official", name: "Official" },
          { id: "personal", name: "Personal" },
          { id: "other", name: "Other" },
        ]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.PurposeVisit && Boolean(formik.errors.PurposeVisit)
        }
      />

      <Grid item xs={12} sm={12}>
        <TextFieldWithCSS
          fullWidth
          variant="outlined"
          label={
            <Fragment>
              Please fill in the reason for your appointment
              {formik.values.PurposeVisit === "other" && (
                <span
                  style={{
                    color: "red",
                    float: "right",
                    paddingLeft: "5px",
                  }}
                >
                  *
                </span>
              )}
            </Fragment>
          }
          placeholder="Please fill in the reason for your appointment"
          name="reason"
          value={formik.values.reason}
          onChange={(event) => {
            if (event.target.value.length <= 250) {
              formik.handleChange(event);
            }
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.reason && Boolean(formik.errors.reason)}
          multiline={true}
          rows={6}
          helperText={`${formik.values.reason.length} / 250 characters`}
        />
      </Grid>
    </Grid>
  );
}

export default Form;
