import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import useAxios from "./../../hooks/useAxios";
import { Box, Grid, Typography, Divider } from "@mui/material";
import styled from "styled-components";
import convertDate from "./../../utils/convertDate";
import { useNavigate } from "react-router-dom";
import pages from "./../../constants/pages";

const OuterContainer = styled(Box)`
  width: 100%;
  max-width: 900px;
  margin: 20px auto;
  padding: 0px 16px 10vh 16px;
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Tab = styled.div`
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 8px;
  text-align: center;
  background: ${(props) => (props.active ? "#1976d2" : "#ffffff")};
  color: ${(props) => (props.active ? "#ffffff" : "#1976d2")};
  border: 2px solid #1976d2;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: ${(props) => (props.active ? "#1565c0" : "#e3f2fd")};
  }
`;

const RecordCard = styled(Box)`
  padding: 16px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
`;

const StatusIndicator = styled(Typography)`
  font-weight: bold;
  color: ${(props) =>
    props.status === "pending"
      ? "#d32f2f"
      : props.status === "completed"
      ? "#388e3c"
      : "#616161"};
`;

const DateHeader = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  color: #424242;
  margin-bottom: 8px;
`;

export default function Transaction() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [transaction, setTransaction] = useState([]);
  const [selectOption, setSelectOption] = useState("appointment");

  useEffect(() => {
    axios({
      url:
        "/admin/profile/get-user-bookings?phone_number=" +
        visitorData.phoneNumber,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTransaction(response.data);
      }
    });
  }, []);

  return (
    <Fragment>
      <OuterContainer>
        {/* Tabs */}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={6}>
            <Tab
              active={selectOption === "appointment"}
              onClick={() => setSelectOption("appointment")}
            >
              Appointments
            </Tab>
          </Grid>
          <Grid item xs={6}>
            <Tab
              active={selectOption === "event"}
              onClick={() => setSelectOption("event")}
            >
              Events
            </Tab>
          </Grid>
        </Grid>

        {/* History List */}
        {selectOption === "appointment" &&
          transaction
            .filter((item) => item.booking_type === 2)
            .map((item, index) => (
              <Fragment key={index}>
                <DateHeader>{convertDate(item?.appointment_date)}</DateHeader>
                <RecordCard>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={8}>
                      <Typography variant="body1" fontWeight="bold">
                        Officer: {item?.officer_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Time: {item?.appointment_time}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="right">
                      <StatusIndicator status={item?.appointment_status}>
                        {item?.appointment_status.toUpperCase()}
                      </StatusIndicator>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Typography
                    variant="body2"
                    textAlign="right"
                    color="textSecondary"
                  >
                    Created: {convertDate(item?.created_at)}
                  </Typography>
                </RecordCard>
              </Fragment>
            ))}

        {selectOption === "event" &&
          transaction
            .filter((item) => item.booking_type === 1)
            .map((item, index) => (
              <Fragment key={index}>
                <DateHeader>{convertDate(item?.created_at)}</DateHeader>
                <RecordCard>
                  <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    onClick={() =>
                      navigate(
                        `${pages.EventSummary?.route}${item.event_id}/${item.visitor_id}`
                      )
                    }
                  >
                    <Grid item xs={8}>
                      <Typography variant="body1" fontWeight="bold">
                        Event: {item?.event_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Address: {item?.event_address}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="right">
                      <Typography
                        variant="body1"
                        color={
                          item?.payment_status !== "Completed"
                            ? "error"
                            : "success"
                        }
                      >
                        &#8377; {item?.total_amount}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Typography
                    variant="body2"
                    textAlign="right"
                    color="textSecondary"
                  >
                    Booking ID: {item?.booking_id}
                  </Typography>
                </RecordCard>
              </Fragment>
            ))}
      </OuterContainer>
    </Fragment>
  );
}
