import { Box } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { IoIosHome } from "react-icons/io";
import { FaUserLarge } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { Paper, Grid, Typography, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import Color from "../../../theme/color";
import pages from "../../../constants/pages";

const TabButton = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-size: 7.5px;
  font-family: "Open Sans, sans-serif";
  font-weight: 400;
`;

const OuterBox = styled(Box)`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: 999;
  border-radius: 50px 50px 0 0;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
`;

const BoxPaper = styled(Paper)`
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: white;
  box-shadow: rgb(0, 153, 99) 0px 0px 1px 1px;
  padding: 1.5px;
`;

export default function MenuFooter() {
  const navigate = useNavigate();
  const location = useLocation();
  const { visitorData } = useSelector((state) => state.memberDetails);
  const [selectOption, setSelectOption] = useState(location.pathname);

  const stopCamera = () => {
    const stream = document.querySelector("video")?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      document.querySelector("video").srcObject = null;
    }
  };

  const handleNavigation = (route) => {
    if (selectOption !== route) {
      stopCamera();
      setSelectOption(route);
      navigate(route);
    }
  };

  useEffect(() => {
    setSelectOption(location.pathname);
  }, [location]);

  return (
    <OuterBox>
      <BoxPaper>
        {/* Home Button */}
        <Grid
          item
          container
          justifyContent="center"
          xs={6}
          onClick={() => handleNavigation(pages.home.route)}
          sx={{
            transition: "0.25s ease-out",
          }}
        >
          <Tooltip title="Home">
            <TabButton>
              <IoIosHome
                style={{
                  fontSize: "25px",
                  color: selectOption === "/" ? Color.main : "rgb(111 111 111)",
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  fontWeight: 900,
                  color: selectOption === "/" ? Color.main : "rgb(111 111 111)",
                }}
              >
                Home
              </Typography>
            </TabButton>
          </Tooltip>
        </Grid>

        {/* History Button */}
        <Grid
          item
          container
          justifyContent="center"
          xs={6}
          onClick={() => {
            if (!visitorData.ID) {
              navigate(pages.appointment.route + "login");
            } else {
              handleNavigation(pages.Transaction.route);
            }
          }}
          sx={{
            transition: "0.25s ease-out",
          }}
        >
          <Tooltip title="History">
            <TabButton>
              <FaUserLarge
                style={{
                  fontSize: "20px",
                  color:
                    selectOption === "/transaction"
                      ? Color.main
                      : "rgb(111 111 111)",
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  color:
                    selectOption === "/transaction"
                      ? Color.main
                      : "rgb(111 111 111)",
                }}
              >
                History
              </Typography>
            </TabButton>
          </Tooltip>
        </Grid>
      </BoxPaper>
    </OuterBox>
  );
}
