import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { MdVerified } from "react-icons/md";
import { IoIosInformationCircle } from "react-icons/io";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAxios } from "../../hooks";
import pages from "./../../constants/pages";
import convertDate from "./../../utils/convertDate";
import ImagePopup from "./ImagePopup";
import LoadingButton from "./../../components/LoadingButton";
import ParkingDetialsPopup from "./ParkingDetails";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Box)`
  width: 100%;
  border-radius: 15px;
  min-height: 50vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
`;

const TableCells = styled(TableCell)`
  // display: flex;
`;

const EventBox = styled(Grid)`
  background-image: url(${(props) => props.backgroundImage});
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
  margin: 0px !important;
  width: 100%;
  height: 25vh !important;
  border-top-left-radius: 12.5px;
  border-top-right-radius: 12.5px;
`;

const GradientOverlay = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12.5px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 50%,
    rgb(255 255 255 / 93%) 100%
  );
`;

export default function EventSummary() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { eventID, id } = useParams();

  const [eventDetails, setEventDetails] = useState({});
  const [userData, setUserData] = useState(state);

  const [open, setOpen] = useState(false);
  const [openParkingDetial, setOpenParkingDetial] = useState(false);

  const userPayloadString = localStorage.getItem("userPayload");
  const userPayload = JSON.parse(userPayloadString);

  useEffect(() => {
    axios({
      url: `admin/events/event-details?event_id=${eventID}`,
      method: "GET",
      customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
    }).then((response) => {
      if (response.status) {
        setEventDetails(response.data);
      }
    });

    if (!Boolean(state)) {
      axios({
        url:
          "event/visitor/check-booking" +
          "?event_id=" +
          eventID +
          "&visitor_id=" +
          id,
        disableRedirect: true,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setUserData({ userData: response.data });
        }
      });
    }
  }, [axios, eventID]);

  const getButtonText = (item) => {
    if (item?.journey_status?.payment_status === "Pending")
      return "Payment Pending";
    if (item?.journey_status?.is_verified === false)
      return "Verification Pending";
    if (item?.journey_status?.send_consent === true) return "Consent Pending";
    // if (item?.journey_status?.consent === false) return "Consent Pending";
    return false;
  };

  const handleClick = (item) => {
    const { journey_status, event_id, visitor_id, booking_id } = item;
    if (journey_status?.payment_status === "Pending") {
      navigate(`${pages.EventBooking.route}/${event_id}/${visitor_id}`);
    } else if (journey_status?.is_verified === false) {
      navigate(
        `${pages.Verification.route}?visitor_id=${visitor_id}&booking_id=${booking_id}&event_id=${event_id}`
      );
    } else if (journey_status?.send_consent === true) {
      navigate(
        `${pages.Consent.route}?booking_id=${booking_id}&visitorId=${visitor_id}`
      );
    }
  };

  const renderInfoItem = (label, value) => (
    <TableRow>
      <TableCells sx={{ width: "40%" }}>
        <Typography
          variant="body1"
          style={{ color: "rgb(150 150 150)" }}
          fontWeight={900}
        >
          {label}
        </Typography>
      </TableCells>
      <TableCells sx={{ width: "60%" }}>
        <Tooltip title={value}>
          <Typography
            variant="h6"
            fontWeight={900}
            sx={{
              color: value === "pending" ? "red" : "black",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "1rem",
              "@media (max-width: 425px)": {
                fontSize: "0.875rem",
              },
            }}
          >
            {value?.toUpperCase()}
          </Typography>
        </Tooltip>
      </TableCells>
    </TableRow>
  );

  return (
    <OuterBox>
      {/* <Grid
        item
        container
        sx={{ marginTop: "12.5px", display: "flex", justifyContent: "end" }}
      >
        <Button
          variant="outlined"
          sx={{
            fontWeight: "bold",
            // border: "2px solid",
            // borderRadius: "9.5px",
          }}
          onClick={() =>
            navigate(pages.BoardingPass.route, {
              state: state?.userData[0]?.visitor_id,
            })
          }
        >
          Boarding Pass
        </Button>
      </Grid> */}

      {eventDetails?.is_parking && (
        <Grid
          item
          container
          sx={{ marginTop: "12.5px", display: "flex", justifyContent: "end" }}
        >
          <Button
            variant="outlined"
            sx={{
              fontWeight: "bold",
            }}
            onClick={() => setOpenParkingDetial(true)}
          >
            Parking Details
          </Button>
        </Grid>
      )}

      {userData?.userData?.map((item, index) => {
        return (
          <OuterPaper
            sx={{
              padding: { xs: "30px 0px 0px 0px", sm: "30px 80px 0px 80px" },
            }}
          >
            {/* <div class="id-card-hook"></div> */}
            <Grid
              container
              flexDirection="column"
              padding={{ xs: "0px", sm: "0px", md: "0px" }}
              sx={{ height: "100%" }}
            >
              <Grid
                item
                container
                sx={{
                  height: "100%",
                  position: "relative",
                  borderRadius: "12.5px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  justifyContent: "center",
                  // border: "2px solid #484848",
                }}
              >
                <Grid item padding="20px">
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bolder",
                      "-webkit-text-stroke-width": ".5px",
                    }}
                  >
                    Booking Summary
                  </Typography>
                </Grid>

                <EventBox
                  backgroundImage={
                    eventDetails?.banner_image ||
                    "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/3e9c86447ad14afb8b260fe48efe0eb0/1724237319019_image-cache.jpeg"
                  }
                  style={{
                    position: "relative",
                    backgroundRepeat: "no-repeat",
                    margin: " 0px !important",
                    width: "92.5%",
                    height: "25vh !important",
                    borderRadius: "25px",
                  }}
                >
                  {/* <img src={eventDetails?.banner_image} /> */}

                  <GradientOverlay />
                  <Grid
                    item
                    container
                    position="absolute"
                    bottom={0}
                    sx={{ justifyContent: "space-between", padding: "20px 5%" }}
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        sx={{
                          WebkitTextStrokeWidth: ".5px",
                          fontSize: { xs: "1rem", md: "1.25rem" },
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {eventDetails.event_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="#747474"
                      >
                        {convertDate(eventDetails.event_start_date)}{" "}
                        <PlaceOutlinedIcon
                          sx={{ verticalAlign: "bottom", color: "#3869db" }}
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        borderRadius: "12px",
                        background: "rgb(28 28 28/1)",
                        color: "white",
                        padding: "10px",
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        {item.payment_status === "Completed" ? (
                          <VerifiedIcon
                            sx={{
                              color: "green",
                              verticalAlign: "sub",
                              fontSize: "17.5px",
                            }}
                          />
                        ) : (
                          <NewReleasesIcon
                            sx={{
                              color: "red",
                              verticalAlign: "sub",
                              fontSize: "17.5px",
                            }}
                          />
                        )}
                        {"  "}
                        {eventDetails?.ticket_cost}
                      </Typography>
                      <Typography variant="body1" fontWeight="bold">
                        {item.payment_status}
                      </Typography>
                    </Grid>
                  </Grid>
                </EventBox>

                <Grid item sx={{ width: "100%" }} padding="30px">
                  <Grid
                    item
                    container
                    justifyContent="center"
                    flexDirection={"column"}
                    sx={{ overflow: "hidden" }}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{
                        alignContent: "center",
                        justifyContent: "space-between",
                        textAlign: { xs: "center", sm: "start" },
                      }}
                    >
                      <Grid item xs={12}>
                        <Table>
                          <TableBody>
                            {renderInfoItem(
                              "Name",
                              item?.visitor_info?.first_name
                            )}
                            {renderInfoItem(
                              "Phone Number",
                              item?.visitor_info?.phone_number
                            )}
                            {renderInfoItem(
                              "Email ID",
                              item?.visitor_info?.email_id
                            )}
                          </TableBody>
                        </Table>
                      </Grid>

                      {Boolean(
                        item?.journey_status?.qr_link &&
                          !item?.journey_status?.send_consent
                      ) && (
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            justifyContent:
                              !item.journey_status.data_qr_link && "center",
                            border: " 1px solid rgb(232, 222, 207)",
                            borderRadius: "12.5px",
                            overflowY: "scroll",
                            scrollbarWidth: "none",
                            flexDirection: "row",
                            padding: "10px 5px !important",
                          }}
                          xs={12}
                          width={0}
                        >
                          <img
                            src={item?.journey_status?.qr_link}
                            className="QrCodeImage"
                            style={{ margin: "0px 20px" }}
                          />
                          {item.journey_status.data_qr_link && (
                            <img
                              src={item.journey_status.data_qr_link}
                              className="QrCodeImage"
                              style={{ margin: "0px 20px" }}
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{ textAlign: { xs: "center", sm: "start" } }}
                    >
                      <Table>
                        <TableBody>
                          {renderInfoItem("Booking ID", item?.booking_id)}
                          {renderInfoItem(
                            "Registration Date",
                            convertDate(item?.registered_at)
                          )}
                        </TableBody>
                      </Table>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      {!Boolean(
                        item?.journey_status?.qr_link &&
                          !item?.journey_status?.send_consent
                      ) && (
                        <Grid
                          item
                          container
                          xs={12}
                          sm={6}
                          sx={{ alignSelf: "center", justifyContent: "center" }}
                        >
                          {getButtonText(item) && (
                            <LoadingButton
                              onClick={() => handleClick(item)}
                              variant="contained"
                              style={{ width: "70%" }}
                            >
                              {getButtonText(item)}
                            </LoadingButton>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </OuterPaper>
        );
      })}

      {Boolean(state?.match_percentage) && (
        <Grid
          container
          pt={"10px"}
          sx={{
            width: "100%",
            padding: { xs: "30px 0px 0px 0px", sm: "30px 80px 0px 80px" },
          }}
        >
          <Grid
            item
            container
            sx={{
              boxShadow: "rgb(0 0 0 / 53%) 0px 3px 15px",
              padding: "8.5px",
              borderRadius: "12.5px",
              height: "100px",
            }}
          >
            <Grid item xs={4} sx={{ position: "relative" }}>
              <img
                width="100%"
                height="80px"
                style={{ objectFit: "initial" }}
                src={"data:image/png;base64," + userPayload.doc_face_image}
                // src={state.face_data}
                alt="Preview"
                style={{ borderRadius: "8px" }}
              />

              <MdVerified
                style={{
                  color: "green",
                  bottom: "10px",
                  right: "5px",
                  fontSize: "25px",
                  position: "absolute",
                  borderRadius: "15px",
                  background: "white",
                }}
              />
            </Grid>
            <Grid
              item
              xs={8}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  padding: "10px",
                  fontWeight: "bold",
                  "-webkit-text-stroke": ".5px",
                }}
              >
                Verification Done{"  "}
                <IoIosInformationCircle
                  style={{
                    color: "blue",
                    fontSize: "20px",
                    verticalAlign: "middle",
                  }}
                  onClick={() => setOpen(true)}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {state && (
        <ImagePopup
          open={open}
          setOpen={setOpen}
          state={state}
          userPayload={userPayload}
        />
      )}

      <ParkingDetialsPopup
        bookingId={userData?.userData[0]?.booking_id}
        openParkingDetial={openParkingDetial}
        setOpenParkingDetial={setOpenParkingDetial}
      />
    </OuterBox>
  );
}
