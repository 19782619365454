import styled from "styled-components";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import useAxios from "./../../hooks/useAxios";
import EventSlider from "./EventSlider";
import { TiTicket } from "react-icons/ti";
import { RiGovernmentLine } from "react-icons/ri";
import { MdSportsHandball } from "react-icons/md";
import { BiSolidHotel } from "react-icons/bi";
import pages from "../../constants/pages";

const OuterBox = styled(Box)`
  display: flex;
  justify-content: center;
  // padding: 20px;
  background: linear-gradient(to right, #f7fbfc, #e2f4fc);
  min-height: 100vh;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  max-width: 1200px;
  // padding: 30px;
  // border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const CategoryItem = styled(Grid)`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #e0f2f8;
  border-radius: 5px;
  transition: all 0.3s ease;
  background: linear-gradient(to bottom, #ffffff, #f0f8ff);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  margin: 0px auto;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }

  @media screen and (max-width: 768px) {
    width: 150px;
    height: 150px;
  }

  @media screen and (max-width: 480px) {
    width: 100px;
    height: 100px;
  }
`;

const CategoryGrid = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 25px;
  padding: 0px 35px;
`;

const CategoryIcon = styled.div`
  font-size: 35px;
  color: ${(props) => props.color || "#0073e6"};
  // margin-bottom: 10px;
`;

const HomeComponent = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const { visitorData } = useSelector((state) => state.memberDetails);
  const [bannerList, setBannerList] = useState([]);

  useEffect(() => {
    axios({ url: "/admin/profile/get-event-banner" }).then((response) => {
      if (response.status) {
        setBannerList(response.data);
      }
    });
  }, []);

  const categories = [
    {
      name: "Event",
      icon: <TiTicket />,
      color: "#edbc00",
      route: pages.eventListPage.route,
    },
    {
      name: "Govt",
      icon: <RiGovernmentLine />,
      color: "#0073e6",
      route: visitorData.ID
        ? pages.AppointmentBooking.route
        : `${pages.appointment.route}bookappointment`,
    },
    {
      name: "Sports",
      icon: <MdSportsHandball />,
      color: "#28a745",
      route: pages.eventListPage.route,
    },
    {
      name: "Hotels",
      icon: <BiSolidHotel />,
      color: "#dc3545",
      route: pages.eventListPage.route,
    },
  ];

  return (
    <Fragment>
      <OuterBox>
        <OuterPaper>
          <EventSlider bannerList={bannerList} backgroundColor="#FFF" />
          <Typography variant="h4" fontWeight="900" align="center" gutterBottom>
            Browse by Category
          </Typography>
          <CategoryGrid>
            {categories.map((category, index) => (
              <CategoryItem
                key={index}
                onClick={() => navigate(category.route)}
              >
                <CategoryIcon color={category.color}>
                  {category.icon}
                </CategoryIcon>
                <Typography
                  variant="h6"
                  fontWeight="700"
                  color={category.color}
                >
                  {category.name}
                </Typography>
              </CategoryItem>
            ))}
          </CategoryGrid>
        </OuterPaper>
      </OuterBox>
    </Fragment>
  );
};

export default HomeComponent;
