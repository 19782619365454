import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";

import pages from "../../constants/pages";
import OTPVerification from "./OTPVerification";
import { useAxios, useAlert } from "../../hooks";
import MobileNumberInput from "./MobileNumberInput";
import { SubheaderSection } from "./../../components";
import Error from "../Error/Error";
import EventBookingSuccess from "../Success/EventBookingSuccess";
import { signInMember } from "../../redux/slices/memberSlice";

const MobileVerification = ({
  selectedOption,
  numberOfInputs = 4,
  setProcced,
}) => {
  const axios = useAxios();
  const { alert } = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { eventID } = useParams();

  const [loading, setLoading] = useState(false);
  const [userConsent, setUserConsent] = useState(0);
  const [eventDetail, setEventDetail] = useState(false);
  const [bannerImage, setBannerImage] = useState("");
  const [otp, setOTP] = useState(new Array(numberOfInputs).fill(""));
  const [mobileNumberOtpGenrated, setMobileNumberOtpGenrated] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(
    sessionStorage.getItem("mobile") ? sessionStorage.getItem("mobile") : ""
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleOtpGoBack = () => {
    setMobileNumberOtpGenrated(false);
  };

  const handleUserConsentChange = (event) => {
    const isChecked = event.target.checked;

    const consentValue = isChecked ? "1" : "0";
    setUserConsent(consentValue);
  };

  const handleOTPSubmit = () => {
    const otpString = otp.join("");
    const numericOtpString = otpString.replace(/\D/g, "");
    const numericOtp = parseInt(numericOtpString, 10);

    if (isNaN(numericOtp) || numericOtpString.length !== 4) {
      dispatch(
        alert({
          type: "error",
          message: "Invalid OTP. Please enter a 4-digit numeric code.",
        })
      );
      return;
    }

    setLoading(true);

    axios({
      url: `event/booking/verify-otp?mobile_no=91${mobileNumber}&otp=${numericOtp}&on_whatsapp=${userConsent}`,
      method: "POST",
    })
      .then((response) => {
        if (response.status) {
          dispatch(signInMember({ visitorData: response.data }));
          if (location.pathname == "/event/login") {
            navigate(pages.home.route);
          } else if (response.data._id) {
            sessionStorage.setItem(
              "is_verified",
              response.data.is_verified || true
            );
            sessionStorage.setItem("visitorId", response.data._id);

            if (eventID === "bookappointment") {
              navigate(pages.AppointmentBooking.route, {
                state: response.data,
              });
            } else {
              sessionStorage.setItem("eventId", eventID);
              navigate(
                pages.EventBooking.route +
                  "/" +
                  eventID +
                  "/" +
                  response.data?._id,
                { state: response.data }
              );
            }

            dispatch(
              alert({
                type: "success",
                message: response.message.displayMessage,
              })
            );
          } else {
            dispatch(
              alert({
                type: "error",
                message: response.message.displayMessage,
              })
            );
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error sending OTP:", error);
      });
  };

  const handleResendOTP = () => {
    setLoading(true);
    setOTP(new Array(numberOfInputs).fill(""));
    axios({
      url: `event/booking/resend-otp?mobile_no=91${mobileNumber}`,
      method: "POST",
    })
      .then((response) => {
        if (response.status) {
          dispatch(
            alert({
              type: "success",
              message: response.message.displayMessage,
            })
          );

          setMobileNumberOtpGenrated(true);
        }
      })
      .catch((error) => {
        console.error("Error sending OTP:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetOTP = () => {
    setOTP(new Array(numberOfInputs).fill(""));
    setLoading(true);

    if (eventID !== "bookappointment") {
      axios({
        url:
          "event/visitor/check-booking?event_id=" +
          eventID +
          "&mobile_number=91" +
          mobileNumber,
        disableRedirect: true,
        disableAlert: false,
      })
        .then((response) => {
          if (response.status) {
            if (response.data.length > 0) {
              if (response.data[0].payment_status === "Pending") {
                navigate(
                  pages.EventBooking.route +
                    "/" +
                    response.data[0]?.event_id +
                    "/" +
                    response.data[0]?._id,
                  {
                    state: {
                      userData: response.data,
                    },
                  }
                );
              }

              if (response.data[0].payment_status === "Completed") {
                navigate(
                  pages.EventSummary.route +
                    response.data[0].event_id +
                    "/" +
                    response.data[0]._id,
                  {
                    state: {
                      userData: response.data,
                    },
                  }
                );
              }

              setLoading(false);
            } else {
              setOTP(new Array(numberOfInputs).fill(""));
              setLoading(true);

              axios({
                url: `event/booking/send-otp?mobile_no=91${mobileNumber}`,
                method: "POST",
              })
                .then((response) => {
                  if (response.status) {
                    dispatch(
                      alert({
                        type: "success",
                        message: response.message.displayMessage,
                      })
                    );
                    setMobileNumberOtpGenrated(true);
                  }
                  setLoading(false);
                })
                .catch((error) => {
                  console.error("Error sending OTP:", error);
                  setLoading(false);
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error");
        });
    } else {
      axios({
        url: `event/booking/send-otp?mobile_no=91${mobileNumber}`,
        method: "POST",
      })
        .then((response) => {
          if (response.status) {
            dispatch(
              alert({
                type: "success",
                message: response.message.displayMessage,
              })
            );
            setMobileNumberOtpGenrated(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error sending OTP:", error);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (eventID && eventID !== "bookappointment") {
      axios({
        url: "admin/events/event-details?event_id=" + eventID,
        method: "GET",
        customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
      }).then((response) => {
        setEventDetail(response.data);
      });
    }
  }, []);

  return (
    <Fragment>
      <Grid container flexDirection={"column"}>
        <Grid item container className="eventImageBox">
          <div
            style={{ width: "100%", position: "relative", overflow: "hidden" }}
          >
            <img
              style={{
                width: "100%",
                height: "35vh",
              }}
              src={
                eventDetail?.banner_image || location.pathname == "/event/login"
                  ? "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a2d75083a653441c8541c4de8c0e3088/1733215908446_clipboard-image%20%281%29.png"
                  : eventID === "bookappointment"
                  ? "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a2d75083a653441c8541c4de8c0e3088/1728475638747_appointment.png"
                  : "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a06ed04d3ab645418731c85668acecca/1727690040770_xTk9ZvMnbIiIew7IpW.gif"
              }
            />
          </div>
        </Grid>

        {mobileNumberOtpGenrated ? (
          <Grid item padding={"20px"}>
            <Grid item container justifyContent={"center"}>
              <Grid item className="companyBanner" xs={6}>
                <img
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "12.5px" }}
                  src={
                    eventDetail?.banner_image ||
                    location.pathname == "/event/login"
                      ? "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a2d75083a653441c8541c4de8c0e3088/1733215908446_clipboard-image%20%281%29.png"
                      : eventID === "bookappointment"
                      ? "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a2d75083a653441c8541c4de8c0e3088/1728475638747_appointment.png"
                      : "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a06ed04d3ab645418731c85668acecca/1727690040770_xTk9ZvMnbIiIew7IpW.gif"
                  }
                ></img>
              </Grid>

              <Grid item md={12} sl={6} sx={{ alignContent: "center" }}>
                <SubheaderSection
                  textAlign="center"
                  title="Mobile Verification"
                  content={`Please enter the OTP received on your mobile number <b>${mobileNumber}</b>`}
                />

                <OTPVerification
                  handleOtpGoBack={handleOtpGoBack}
                  loading={loading}
                  otpLength={numberOfInputs}
                  onSubmit={handleOTPSubmit}
                  onResend={handleResendOTP}
                  setOTP={setOTP}
                  otp={otp}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item padding={"10px"}>
            <Grid item container>
              <Grid item className="companyBanner" xs={6}>
                <img
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "12.5px" }}
                  src={
                    eventDetail?.banner_image ||
                    location.pathname == "/event/login"
                      ? "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a2d75083a653441c8541c4de8c0e3088/1733215908446_clipboard-image%20%281%29.png"
                      : eventID === "bookappointment"
                      ? "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a2d75083a653441c8541c4de8c0e3088/1728475638747_appointment.png"
                      : "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a06ed04d3ab645418731c85668acecca/1727690040770_xTk9ZvMnbIiIew7IpW.gif"
                  }
                ></img>
              </Grid>

              <Grid
                item
                md={12}
                sl={6}
                p={"10px"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Grid
                  item
                  container
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  <SubheaderSection
                    textAlign="center"
                    title=" Mobile Verification"
                    content={`Enter your mobile number to receive a verification code and proceed with your ${
                      eventID === "bookappointment" ? "appointment" : "booking"
                    }.`}
                  />

                  <MobileNumberInput
                    handleGoBack={handleGoBack}
                    handleUserConsentChange={handleUserConsentChange}
                    loading={loading}
                    onSubmit={handleGetOTP}
                    setMobileNumber={setMobileNumber}
                    mobileNumber={mobileNumber}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default MobileVerification;
