import React, { Fragment, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import { useAxios } from "../../hooks/";
import pages from "../../constants/pages";
import {
  OuterBox,
  SubheaderSection,
  LoadingButton,
  ButtonContainer,
} from "./../../components";

import EventBookingSuccess from "./../Success/EventBookingSuccess";
import { useSelector } from "react-redux";

export default function VideoPreview({ userData }) {
  const axios = useAxios({ baseURL: "mox_api_2" });

  const axiosDigipass = useAxios({
    baseURL: "digipass",
    disableAuthToken: true,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { visitorData } = useSelector((state) => state.memberDetails);
  const {
    visitor_id,
    id,
    ref_id,
    booking_type,
    bookingId,
    eventId,
    officerID,
    appointmentData,
    appointmentID,
    matchPercentage,
  } = location.state || {};

  const [loading, setLoading] = useState(false);
  const [livelinessError, setLivelinessError] = useState({});

  const [openSuccess, setOpenSuccess] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");

  function captureAgain() {
    navigate(
      pages.checkLiveliness.route +
        (Boolean(bookingId) ? "/?booking_id=" + bookingId : "") +
        (Boolean(appointmentID) ? "/?appointment_id=" + appointmentID : "") +
        "&event_id=" +
        eventId,
      {
        state: {
          visitor_id: visitor_id,
          id: id,
          ref_id: ref_id,
          booking_type: booking_type,
        },
      }
    );
  }

  const checkingLiveliness = async () => {
    setLoading(true);

    const fileurl = userData.state.blob;
    const userDocData = JSON.parse(localStorage.getItem("userPayload"));

    axiosDigipass({
      url: `event/verify/booking`,
      method: "POST",
      disableRedirect: true,
      data: {
        name: " ",
        face_data: fileurl.replace(/^data:image\/[a-z]+;base64,/, ""),
        ...(bookingId && {
          booking_id: bookingId || sessionStorage.getItem("bookingId"),
        }),
        ...(appointmentID &&
          appointmentID != -1 && {
            appointment_id:
              appointmentID || sessionStorage.getItem("appointmentID"),
          }),
        aadhar_data: userDocData,
        ...(officerID && appointmentID != -1 && { officer_id: officerID }),
        ...(appointmentID && {
          slot_ids: appointmentData.slot_id,
        }),
        ...(appointmentID && {
          phone_number: appointmentData.phone_number || visitorData.phoneNumber,
        }),
        ...(appointmentID && {
          appointment_date: appointmentData.appointment_date,
        }),
      },
    })
      .then((response) => {
        if (response.status) {
          if (appointmentID == -1) {
            navigate(pages.home.route);

            let key = visitor_id + "_" + (bookingId || appointmentID);

            localStorage.setItem(key, response.data.encrypted_face_data);
          } else {
            let key = visitor_id + "_" + (bookingId || appointmentID);
            localStorage.setItem(key, response.data.encrypted_face_data);

            let url = !appointmentID
              ? "event/visitor/check-booking" +
                "?event_id=" +
                eventId +
                (visitor_id ? "&visitor_id=" + visitor_id : "") +
                (!visitor_id ? "&booking_id=" + bookingId : "")
              : "appointment/appointment/check-appointment";

            let method = !appointmentID ? "GET" : "POST";

            let data = !appointmentID
              ? {}
              : {
                  ...(officerID && { officer_id: officerID }),
                  ...(appointmentID && {
                    appointment_id: appointmentID,
                  }),
                };

            axios({
              url: url,
              disableRedirect: true,
              method: method,
              data: data,
            }).then((response) => {
              if (response.status) {
                if (
                  Object.keys(response.data).length > 0 &&
                  !response.data.appointment_data
                ) {
                  navigate(
                    pages.EventSummary.route +
                      response.data[0].event_id +
                      "/" +
                      response.data[0].visitor_id,
                    {
                      state: {
                        userData: response.data,
                        match_percentage: matchPercentage,
                        face_data: fileurl,
                      },
                    }
                  );
                } else {
                  navigate(
                    pages.AppointmentSummary.route +
                      `?officer_id=${parseInt(
                        officerID
                      )}&appointment_id=${appointmentID}`,
                    {
                      state: {
                        userData: response.data.appointment_data,
                        match_percentage: matchPercentage,
                        face_data: fileurl,
                      },
                    }
                  );
                }
              }
            });

            // if (response.data.send_consent) {
            //   const destination = `${pages.Consent?.route}/?booking_id=${
            //     bookingId || sessionStorage.getItem("bookingId")
            //   }&visitorId=${visitor_id}`;
            //   navigate(destination, {
            //     state: {
            //       visitor_id: visitor_id,
            //       id: id,
            //       ref_id: ref_id,
            //       booking_type: booking_type,
            //       bookingId: bookingId,
            //       eventId: eventId,
            //     },
            //   });
            // } else {
            // }
          }
        } else {
          setLivelinessError(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        console.error("Error in Aws upload:", error);
      });
  };

  function proceedToNext(fileurl) {
    setLoading(true);
    const userPayloadString = localStorage.getItem("userPayload");
    const userPayload = JSON.parse(userPayloadString);
    axios({
      url: "/booking/appointment/verification",
      method: "PATCH",
      data: {
        livliness_check_status: 1,
        livliness_video_url: fileurl,
        ...userPayload,
        visitor_id: sessionStorage.getItem("visitorId"),
        id: sessionStorage.getItem("id"),
      },
    })
      .then((response) => {
        if (response.status) {
          const destination = `${pages.Success?.route}?ref-id=${ref_id}&booking-type=event`;
          navigate(destination, {
            state: {
              visitor_id: visitor_id,
              id: id,
              ref_id: ref_id,
              booking_type: booking_type,
            },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error update verification detils:", error);
      });
  }

  return (
    <Fragment>
      {livelinessError?.name_match !== undefined &&
      !livelinessError?.name_match &&
      !livelinessError?.face_match ? (
        <OuterBox>
          <SubheaderSection textAlign="center" title="Verification Failed" />

          <form>
            <Box>
              <Grid
                item
                sx={{
                  display: "flex",
                  padding: "10px",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CancelRoundedIcon
                  sx={{ color: "red", fontSize: "60px", marginBottom: "15px" }}
                />
                <Typography variant="h6" sx={{ color: "red" }}>
                  {livelinessError?.booking_already_verified
                    ? "Booking already verified"
                    : "Details Not Match"}
                  <br />
                </Typography>
              </Grid>
            </Box>

            <ButtonContainer>
              <LoadingButton
                onClick={() =>
                  navigate(
                    pages.Verification.route +
                      `?visitor_id=${visitor_id}&booking_type=no&ref_id=DIGI&id=0`
                  )
                }
              >
                Try Again
              </LoadingButton>
            </ButtonContainer>
          </form>
        </OuterBox>
      ) : (
        <Grid container flexDirection={"column"}>
          <Grid item padding={"40px"}>
            <SubheaderSection
              textAlign="center"
              title="Face Verification"
              content=" Before proceeding, check the image preview"
            />

            <form>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Grid
                  item
                  // xs={6}
                  style={{
                    textAlign: "center",
                    border: "40px solid rgb(255, 255, 255)",
                  }}
                  className="videoBox"
                  position={"relative"}
                  width={"30%"}
                >
                  <img
                    // className="userImage"
                    id="video"
                    width="100%"
                    height="100%"
                    style={{ objectFit: "initial" }}
                    src={userData.state.blob}
                    alt="Preview"
                    style={{ borderRadius: "15px" }}
                  />
                </Grid>

                {livelinessError?.face_match && (
                  <Grid
                    sx={{
                      padding: "10px",
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "red", mb: 2 }}>
                      Verification Failed
                    </Typography>
                    <Typography variant="body1">
                      Face Don't Match
                      <br />
                    </Typography>
                  </Grid>
                )}

                {loading && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ marginBottom: "16px" }}
                  >
                    It may take a few seconds
                  </Typography>
                )}
              </Box>

              <ButtonContainer>
                {!Boolean(livelinessError?.name_match) && (
                  <LoadingButton
                    loading={loading}
                    className="proceedNextBtn"
                    onClick={checkingLiveliness}
                  >
                    Proceed Next
                  </LoadingButton>
                )}

                <LoadingButton
                  onClick={captureAgain}
                  disabled={loading}
                  className="captureAgainBtn"
                  backgroundColor="rgb(245 219 162)"
                >
                  Capture Again
                </LoadingButton>
              </ButtonContainer>
            </form>
          </Grid>
        </Grid>
      )}

      <EventBookingSuccess
        open={openSuccess}
        setOpen={setOpenSuccess}
        SuccessMessage={displayMessage}
      />
    </Fragment>
  );
}
