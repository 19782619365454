import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Error from "../../Error/Error";
import Form from "./form components/Form";
import { Loader } from "../../../components";
import pages from "./../../../constants/pages";
import { RAZORPAY_KEY } from "../../../config/config";
import { SubheaderSection } from "./../../../components";
import { useAlert, useAxios, useComponent } from "../../../hooks/";
import {
  eventBokingObject,
  objectFromData,
  objectFromLocationData,
} from "./form components/eventBookingObject";
import EventBookingSuccess from "../../Success/EventBookingSuccess";

const MyComponent = () => {
  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loader } = useComponent();
  const { state } = useLocation();
  const { eventID, id } = useParams();
  const axiosApi = useAxios({ baseURL: "mox_api_2" });

  const [open, setOpen] = useState(false);
  const [payment, setPayment] = useState(false);
  const [parking, setParking] = useState(false);
  const [isDependent, setIsDependent] = useState(false);
  const [eventObject, setEventObject] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [subEventOptions, setSubEventOptions] = useState([]);
  const [displayMessage, setDisplayMessage] = useState(undefined);
  const { visitorData } = useSelector((state) => state.memberDetails);
  const [vehicleDetails, setVehicleDetails] = useState([]);

  useEffect(() => {
    loader.start(1);

    axiosApi({
      url: "event/booking/states",
      method: "GET",
    })
      .then((response) => {
        if (response.status) {
          if (response.data.length > 0) {
            const formattedState = response.data.map((state) => ({
              id: state?.state_id,
              name: state?.state_name,
            }));
            setStateOptions(formattedState);
          } else {
            dispatch(
              alert({
                type: "error",
                message: response.message?.displayMessage,
              })
            );
          }
        }
        loader.apiComplete();
      })
      .catch((error) => {
        console.error("Error in fetching states list", error);
      });

    axiosApi({
      url: "admin/events/event-details?event_id=" + eventID,
      method: "GET",
      customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
    })
      .then((response) => {
        if (response.status) {
          if (
            response.data?.length > 0 ||
            Object.keys(response.data)?.length > 0
          ) {
            if (eventID) {
              setEventOptions([response.data]);
              formik.setFieldValue("event", response.data?.event_id);
              setEventObject(response.data);
            } else {
              setEventOptions(response.data);
            }
          } else {
            dispatch(
              alert({
                type: "error",
                message: response.message?.displayMessage,
              })
            );
          }
        }
        loader.apiComplete();
      })
      .catch((error) => {
        console.error("Error in fetching events list", error);
      });

    axiosApi({
      url: "/admin/parking/vehicle-details",
      method: "GET",
      customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
      disableRedirect: true,
    })
      .then((response) => {
        if (response.status) {
          setVehicleDetails(response.data);
        }
      })
      .catch((error) => {
        console.error("Error in fetching events list", error);
      });
  }, [loader]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const onSubmit = async (values) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    let totalPrice =
      parseInt(values?.eventAmount) +
      (values?.subEventAmount ? values?.subEventAmount : 0);
    loader.start();
    axiosApi({
      url: "event/booking/book-event",
      method: "POST",
      data: {
        ...objectFromData(values, id, subEventOptions),
      },
      disableRedirect: true,
    })
      .then((response) => {
        if (response.status) {
          sessionStorage.setItem("id", response.data?.id);
          sessionStorage.setItem("ref_id", response.data?.ref_id);
          formik.setFieldValue("paymentStatue", response.data.payment_status);
          if (
            response.data.amount > 0 &&
            response.data.payment_status === "Pending"
          ) {
            const options = {
              key: RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
              amount: response.data?.amount * 100,
              currency: "INR",
              name: "Verismart ai",
              description: "Test Transaction",
              image:
                "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/3e9c86447ad14afb8b260fe48efe0eb0/1715171763251_verismart.023471b27268259232de.png",
              order_id: response.data?.order_id,
              handler: async function (respon) {
                const data = {
                  payment_id: respon?.razorpay_payment_id,
                  order_id: respon?.razorpay_order_id,
                  signature: respon?.razorpay_signature,
                };
                if (parking) {
                  axiosApi({
                    url: "/admin/parking/parking-details?is_parking=true",
                    method: "POST",
                    data: {
                      booking_id:
                        response.data?.reference_id ||
                        response.data?.booking_id,
                      vehicle_details: values.vehicle.slice(
                        0,
                        values.vehicleCount
                      ),
                    },
                    disableRedirect: true,
                  }).then((response) => {
                    if (response.status) {
                    }
                  });
                }
                axiosApi({
                  url: "event/booking/payment/status",
                  method: "POST",
                  data: { ...data },
                  disableRedirect: true,
                })
                  .then((resp) => {
                    if (resp.status) {
                      setOpen(true);
                      setPayment(true);
                      setDisplayMessage(resp.message.displayMessage);
                      sessionStorage.setItem(
                        "bookingId",
                        response.data?.reference_id || response.data?.booking_id
                      );
                      loader.apiComplete();
                    } else {
                      dispatch(
                        alert({
                          type: "error",
                          message: response.message?.displayMessage,
                        })
                      );
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              },
              prefill: {
                name: values?.name,
                email: response?.data.email_id,
                contact: response?.data.phone_number,
              },
              notes: {
                address: "Soumya Dey Corporate Office",
              },
              theme: {
                color: "#61dafb",
              },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          } else {
            setOpen(true);
            setPayment(true);
            setDisplayMessage(response.message.displayMessage);
            sessionStorage.setItem(
              "bookingId",
              response.data?.reference_id || response.data?.booking_id
            );
          }
          loader.apiComplete();
        } else {
          setPayment(false);
          setOpen(true);
          setDisplayMessage(response.displayMessage);
          loader.apiComplete();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  var validationSchema = Yup.object().shape({
    email0: Yup.string()
      .email("Invalid email format")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid email format"
      )
      .required("Email is required"),
    name0: Yup.string().required("Name is requied"),
    event: Yup.string().required("Event is required"),
    state0: Yup.string().required("State is required"),
    district0: Yup.string().required("District is required"),
    organization: Yup.string(),
    designation: Yup.string(),
    documentFile: Yup.mixed(),
  });

  useEffect(() => {
    formik.setFieldValue("mobile0", sessionStorage.getItem("mobileNumber"));
    formik.setFieldValue("eventAmount", eventObject.ticket_cost);
    if (eventID) {
      axiosApi({
        url: "admin/events/get-event-badges?event_id=" + eventID,
        method: "GET",
        disableRedirect: true,
      })
        .then((response) => {
          if (response.status) {
            setSubEventOptions(response.data);
          }
        })
        .catch((error) => {
          console.error("Error in fetching events list", error);
        });
    }
  }, [eventObject]);

  const formik = useFormik({
    initialValues:
      Object.keys(state ?? {})?.includes("first_name") ||
      Object.keys(state ?? {})?.includes("userData") ||
      visitorData.ID
        ? objectFromLocationData(
            state,
            eventObject,
            subEventOptions,
            visitorData
          )
        : eventBokingObject,
    validationSchema,
    onSubmit,
  });

  return (
    <Loader>
      <Grid container padding={5}>
        {payment && (
          <Fragment>
            <EventBookingSuccess
              SuccessMessage={displayMessage}
              open={open}
              // setOpen={setOpen}
              buttonContent="Next Process"
              handleOnClick={() => {
                navigate(`${pages.EventSummary?.route}${eventID}/${id}`);
              }}
            />
          </Fragment>
        )}

        <Fragment>
          <SubheaderSection
            textAlign="left"
            title="Book Event"
            content="Please provide the following details to complete your event registration"
          />

          <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
            <Form
              formik={formik}
              eventID={eventID}
              eventObject={eventObject}
              stateOptions={stateOptions}
              eventOptions={eventOptions}
              subEventOptions={subEventOptions}
              setEventObject={setEventObject}
              parking={parking}
              setParking={setParking}
              vehicleDetails={vehicleDetails}
              isDependent={isDependent}
              setIsDependent={setIsDependent}
            />
          </form>
        </Fragment>

        {!payment && (
          <Fragment>
            <Error
              open={open}
              setOpen={setOpen}
              ErrorMessage={displayMessage}
            />
          </Fragment>
        )}
      </Grid>
    </Loader>
  );
};

export default MyComponent;
